<template>
  <div>
    <div class="row">
      <div class="col-md d-flex align-items-center">
        <div>
          <h1 class="h2 pl-2">Sorry, this page could not be found.</h1>
          <p class="pl-2">
            The link you are trying to access is broken or has been moved.
            Either go back or you can try one of these links instead.
          </p>
        </div>
      </div>
    </div>
    <!-- /.row -->
  </div>
</template>

<script>
export default {
  methods: {
    back () {
      window.history.back()
    }
  }
}
</script>
